<template>
    <div>
        <h1>Welcome to the Profile App</h1>
        <button @click="selectUserType('Singaporean')">I'm Singaporean</button>
        <button @click="selectUserType('Foreigner')">I'm a Foreigner</button>
    </div>
</template>

<script>
export default {
  methods: {
    selectUserType(userType) {
      this.$router.push({ name: 'ProfilePage', query: { userType } });
    },
  },
};
</script>
