<template>
  <div>
    <h1>Profiles</h1>
    <ProfileForm @addProfile="addProfile" />
    <ProfileList :profiles="profiles" />
  </div>
</template>

<script>
import { storeToRefs } from 'pinia';
import ProfileForm from '../components/ProfileForm.vue';
import ProfileList from '../components/ProfileList.vue';
import useProfileStore from '../stores/profileStore';

export default {
  components: { ProfileForm, ProfileList },
  setup() {
    const profileStore = useProfileStore();
    const { profiles } = storeToRefs(profileStore);

    profileStore.loadProfiles();

    const addProfile = (profile) => {
      profileStore.addProfile(profile);
    };

    return { profiles, addProfile };
  },
};
</script>
