<template>
  <qrcode-vue :value="value" size="200" />
</template>

<script>
import QrcodeVue from 'qrcode.vue';

export default {
  components: { QrcodeVue },
  props: ['value'],
};
</script>
