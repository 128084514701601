import { createRouter, createWebHistory } from 'vue-router';
import LandingPage from '../views/LandingPage.vue';
import ProfilePage from '../views/ProfilePage.vue';
import ViewProfile from '../views/ViewProfile.vue';

const routes = [
  { path: '/', name: 'LandingPage', component: LandingPage },
  { path: '/profile', name: 'ProfilePage', component: ProfilePage },
  { path: '/profile/view/:id', name: 'ViewProfile', component: ViewProfile },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: () => import('../views/NotFound.vue') },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
