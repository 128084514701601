import { defineStore } from 'pinia';
import localforage from 'localforage';

// Configure localforage
localforage.config({
  name: 'pwa-profile-app',
  storeName: 'profiles',
});

export default defineStore('profileStore', {
  state: () => ({
    profiles: [],
  }),
  actions: {
    async loadProfiles() {
      const profiles = await localforage.getItem('profiles') || [];
      this.profiles = profiles;
    },
    async addProfile(profile) {
      // Create a plain object with only the necessary properties
      const plainProfile = {
        id: profile.id, // Assuming you have an id property
        name: profile.name,
        age: profile.age,
        // Add any other properties you need
      };

      // Load existing profiles
      const profiles = await localforage.getItem('profiles') || [];

      // Add the new profile
      profiles.push(plainProfile);

      // Save the updated profiles array
      await localforage.setItem('profiles', profiles);

      // Update the state
      this.profiles = profiles;
    },
  },
  getters: {
    getProfileById: (state) => (id) => state.profiles.find((profile) => profile.id === id),
  },
});
