<template>
  <div>
    <ul>
      <li v-for="profile in profiles" :key="profile.id">
        <router-link :to="{ name: 'ViewProfile', params: { id: profile.id } }">
          {{ profile.name }} ({{ profile.age }} years old)
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['profiles'],
};
</script>
