<template>
  <form @submit.prevent="submitForm">
    <label for="name">Name: <input id="name" v-model="name" required /></label>
    <label for="age">Age: <input id="age" v-model="age" required /></label>
    <button type="submit">Create Profile</button>
  </form>
</template>

<script>
export default {
  data() {
    return {
      name: '',
      age: '',
    };
  },
  methods: {
    submitForm() {
      const newProfile = {
        id: Date.now().toString(),
        name: this.name,
        age: this.age,
      };
      this.$emit('addProfile', newProfile);
    },
  },
};
</script>
