<template>
  <div>
    <h2>{{ profile.name }}'s Profile</h2>
    <p>Age: {{ profile.age }}</p>
    <QRCode :value="qrCodeValue" />
  </div>
</template>

<script>
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import useProfileStore from '../stores/profileStore';
import QRCode from '../components/QRCode.vue';

export default {
  components: { QRCode },
  setup() {
    const route = useRoute();
    const profileStore = useProfileStore();
    const { getProfileById } = storeToRefs(profileStore);

    const profile = computed(() => getProfileById.value(route.params.id));

    const qrCodeValue = computed(() => `Name: ${profile.value.name}, Age: ${profile.value.age}`);

    return { profile, qrCodeValue };
  },
};
</script>
